import { setRedirectUrl } from "services/identity.service";

export const redirectionAfterLogin = ({ event, auth, router, redirectUrlLoggedIn, redirectUrlLoggedOut }) => {
    event?.preventDefault();
    if (auth) {
        router.push(redirectUrlLoggedIn);
    }
    else {
        setRedirectUrl(redirectUrlLoggedIn);
        router.push(redirectUrlLoggedOut);
    }
}