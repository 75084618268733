import { Svg } from "atoms/SvgIcon";

const IconMail = ({ ...rest }) => {
    return <Svg viewBox='0 0 22 22' type='IconMail' {...rest} >
        <path opacity="0.939" fillRule="evenodd" clipRule="evenodd" d="M4.99308 4.80952C9.93461 4.80184 14.8761 4.80952 19.8176 4.83254C20.8055 4.94571 21.3963 5.49053 21.5901 6.46692C21.6208 9.47477 21.6208 12.4827 21.5901 15.4905C21.3963 16.4669 20.8055 17.0117 19.8176 17.1249C14.8761 17.1556 9.93456 17.1556 4.99308 17.1249C4.13934 17.0539 3.56386 16.6165 3.26663 15.8128C3.21721 15.5318 3.19419 15.2479 3.19757 14.9611C3.19205 14.7029 3.21507 14.4497 3.26663 14.2014C3.558 13.9482 3.83424 13.9636 4.09533 14.2475C4.13998 14.6493 4.18602 15.0483 4.23344 15.4445C5.96418 13.9613 7.69063 12.4728 9.41281 10.9787C7.69063 9.48467 5.96418 7.9961 4.23344 6.51296C4.18602 6.90912 4.13998 7.30814 4.09533 7.70997C3.83424 7.99389 3.558 8.00922 3.26663 7.75601C3.19217 7.28485 3.17683 6.80913 3.22059 6.32881C3.48203 5.43004 4.07286 4.92361 4.99308 4.80952ZM4.99308 5.86842C9.92688 5.81474 14.8684 5.80705 19.8176 5.8454C17.4543 7.8711 15.0909 9.89681 12.7276 11.9225C12.4141 12.1035 12.1379 12.0575 11.8989 11.7844C9.6041 9.80114 7.30216 7.82916 4.99308 5.86842ZM20.5542 6.51296C20.6078 9.48242 20.6155 12.4596 20.5772 15.4445C18.8428 13.9631 17.1163 12.4746 15.3979 10.9787C17.1175 9.48909 18.8363 8.00052 20.5542 6.51296ZM14.5692 11.6233C16.3245 13.1099 18.074 14.6061 19.8176 16.1121C14.8761 16.1428 9.93456 16.1428 4.99308 16.1121C6.71185 14.6388 8.43066 13.1656 10.1494 11.6923C10.1955 11.6616 10.2415 11.6616 10.2875 11.6923C10.7479 12.0913 11.2083 12.4903 11.6687 12.8893C12.1815 13.1634 12.6879 13.148 13.188 12.8433C13.6573 12.4428 14.1177 12.0361 14.5692 11.6233Z" fill="currentColor" />
        <path opacity="0.959" fillRule="evenodd" clipRule="evenodd" d="M2.04692 9.32124C3.12127 9.31355 4.1955 9.32124 5.26963 9.34426C5.69931 9.6512 5.69931 9.9581 5.26963 10.265C4.21074 10.2957 3.15185 10.2957 2.09296 10.265C1.77463 10.0958 1.69022 9.84259 1.83974 9.5054C1.90405 9.43312 1.97311 9.3717 2.04692 9.32124Z" fill="currentColor" />
        <path opacity="0.964" fillRule="evenodd" clipRule="evenodd" d="M0.665623 11.6689C2.20033 11.6612 3.73495 11.6689 5.2695 11.6919C5.69918 11.9989 5.69918 12.3058 5.2695 12.6127C3.71953 12.6434 2.16956 12.6434 0.619585 12.6127C0.246178 12.2816 0.261527 11.967 0.665623 11.6689Z" fill="currentColor" />
    </Svg>
};

export default IconMail;