import React from 'react'
import ImageNext from 'atoms/ImageNext'

import LinkAnchor from './../atoms/LinkAnchor'
import Text from './../atoms/Text'
import Container from './../atoms/Container'
import SocialList from './SocialList'
import IconPhoneCall from 'atoms/SvgIcons/IconPhoneCall.svg'
import IconMail from 'atoms/SvgIcons/IconMail.svg'
import AccordionItem from 'atoms/Accordion'
import { getAuth } from 'services/identity.service'
import { CUSTOMER_TYPE } from 'constant'
import { useRouter } from 'next/router'
import { getCustomerType } from 'helper/getCustomerType'
import { redirectionAfterLogin } from 'core/common/redirectionAfterLogin'

const style = {
    [CUSTOMER_TYPE.INDIVIDUAL]: {
        containerSize: 'lg:max-w-[680px]',
    },
    [CUSTOMER_TYPE.PRACTITIONER]: {
        containerSize: 'lg:max-w-[680px]',
    },
    [CUSTOMER_TYPE.INDIVIDUAL_LOGIN]: {
        containerSize: 'lg:max-w-[600px]',
    },
    [CUSTOMER_TYPE.PRACTITIONER_LOGIN]: {
        containerSize: 'lg:max-w-[600px]',
    },
    [CUSTOMER_TYPE.CHEMIST_LOGIN]: {
        containerSize: 'lg:max-w-[450px]',
    },
}


const Footer = ({ footerLinks, socialLinks, isMobileInnerPage, mobileNo, email }) => {
    const router = useRouter();
    const route = router.pathname.split('/')[1];

    const auth = getAuth();
    const customerType = getCustomerType({ route, auth })

    const onFooterLinkHandler = ({ event, automation }) => {
        switch (automation) {
            case 'footer-vaidya-tool':
                event.preventDefault();
                if (!auth) {
                    router.push('/sign-up-practitioner')
                }

                else {
                    window.location.href = process.env.NEXT_PUBLIC_VEDIC_TOOL_LINK
                }

                break;
            
            case 'footer-medicines':
                redirectionAfterLogin({ event, auth, router, redirectUrlLoggedIn: '/medicines', redirectUrlLoggedOut: '/login-practitioner' })

            default:
                break;
        }
    };

    return (
        <div className={`${isMobileInnerPage ? 'hidden lg:block' : ''} bg-blueSecondary-50`}>
            <Container type='static'>
                <div className='py-6 px-5 flex flex-col lg:flex-row gap-6 lg:gap-9 justify-between flex-wrap lg:flex-nowrap'>

                    <div className='w-full lg:w-max'>
                        <div className='w-full lg:max-w-[408px] flex flex-row lg:flex-col gap-2 justify-between lg:items-start'>

                            <ImageNext className='block' priority src={'/images/nirogstreet.svg'} alt={'nirogstreet'} width={110} height={45} />

                            <div className='whitespace-nowrap'>
                                <Text type='overline' className='lg:text-14-22 text-gray-500 mt-2'>
                                    <IconPhoneCall className='text-primary1-900 inline-block align-middle' size={20} />
                                    <LinkAnchor className='ml-3 align-middle inline-block font-semibold text-gray-900 hover:text-primary1-500' href={`tel:${mobileNo}`} dataAutomation='footer-link-phone-no'>{mobileNo}</LinkAnchor>
                                </Text>

                                <Text type='overline' className='lg:text-14-22 text-gray-500 mt-2'>
                                    <IconMail className='text-primary1-900 inline-block align-middle' size={20} />
                                    <LinkAnchor className='ml-3 align-middle inline-block font-semibold text-gray-900 hover:text-primary1-500' href={`mailto:${email}`} dataAutomation='footer-link-email'>{email}</LinkAnchor>
                                </Text>
                            </div>

                        </div>
                    </div>

                    {/* Web links */}
                    <div className={`${style[customerType]?.containerSize} hidden lg:flex justify-between w-full`}>
                        {footerLinks[customerType]?.map(category => (
                            <div key={category.mainHeading}>
                                <Text type='bodySmall' className='text-gray-900 mb-3' fontWeight={600}>{category.mainHeading}</Text>
                                <ul className='grid grid-cols-1 gap-3 w-full'>
                                    {category.list.map(({ route, label, automation }, index) =>
                                        <li key={label}>
                                            <LinkAnchor key={index} href={route} onClick={(event) => onFooterLinkHandler({ event, automation })} className="text-14-18 font-normal text-gray-600 hover:text-primary1-500" dataAutomation={automation}>{label}</LinkAnchor>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        ))}
                    </div>

                    {/* Mobile links */}
                    <div className='lg:hidden'>
                        {footerLinks[customerType]?.map(category => (
                            <AccordionItem key={category.mainHeading} type={'TRANSPARENT'} className={''} heading={category.mainHeading} dataAutomation={category.automation} px='px-0.5' pb='pb-2' pt='pt-1'>
                                <ul>
                                    {category.list.map(({ route, label, automation }, index) =>
                                        <li key={label}>
                                            <LinkAnchor key={label} href={route} onClick={(event) => onFooterLinkHandler({ event, automation })} className="text-14-18 mb-4 text-gray-600 hover:text-primary1-500" dataAutomation={automation}>{label}</LinkAnchor>
                                        </li>
                                    )}
                                </ul>
                            </AccordionItem>

                        ))}
                    </div>


                    <div>
                        <Text className='text-gray-900 font-semibold mb-2.5 text-sm'>Experience NirogStreet on Mobile</Text>
                        <div className='flex gap-6'>
                            <LinkAnchor href="https://apps.apple.com/in/app/nirogstreet/id1352321621" target="_blank" dataAutomation='footer-link-apps-store'>
                                <ImageNext src='/images/appstore.webp' alt='nirogstreet appstore app' width={112} height={32}/>
                            </LinkAnchor>

                            <LinkAnchor href="https://play.google.com/store/apps/details?id=com.app.nirogstreet" target="_blank" dataAutomation='footer-link-playstore'>
                                <ImageNext src='/images/playstore.webp' alt='nirogstreet playstore app' width={112} height={32} />
                            </LinkAnchor>
                        </div>

                        <Text className='mt-6 mb-2.5 text-gray-900 font-semibold'>Follow Us</Text>
                        <SocialList className='gap-8' socialLinks={socialLinks} />
                    </div>
                </div>
            </Container>
            <div className='border-t px-5 text-center border-gray-300 h-11 flex justify-center items-center'>
                <Text type='body' className='text-12-16 text-gray-900 font-normal'>Copyright © {new Date().getFullYear()} NirogStreet All Rights Reserved</Text>
            </div>
        </div>
    )
}

export default Footer
