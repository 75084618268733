import { CUSTOMER_TYPE, USER_TYPES } from "constant";

export const getCustomerType = ({ route, auth }) => {
    if (auth && [USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(auth?.user?.userType)) {
        return CUSTOMER_TYPE.CHEMIST_LOGIN;
    }

    if (route === 'practitioner' || route === 'login-practitioner' || route === 'sign-up-practitioner') {
        return auth ? CUSTOMER_TYPE.PRACTITIONER_LOGIN : CUSTOMER_TYPE.PRACTITIONER;
    }

    if (auth && [USER_TYPES?.DOCTORS, USER_TYPES?.DOCTOR_DISTRIBUTOR, USER_TYPES?.STUDENTS].includes(auth?.user?.userType)) {
        return CUSTOMER_TYPE.PRACTITIONER_LOGIN;
    }

    if (auth && auth?.user?.userType === USER_TYPES?.PATIENT) {
        return CUSTOMER_TYPE.INDIVIDUAL_LOGIN;
    }

    // Default types when none of the conditions match
    return CUSTOMER_TYPE.INDIVIDUAL; // or any other default type
};
