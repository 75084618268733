import React from 'react';
import LinkAnchor from './../atoms/LinkAnchor';
import IconFacebook from 'atoms/SvgIcons/IconFacebook.svg';
import IconTwitter from 'atoms/SvgIcons/IconTwitter.svg';
import IconLinkedin from 'atoms/SvgIcons/IconLinkedin.svg';
import IconYoutube from 'atoms/SvgIcons/IconYoutube.svg';
import IconCopyFilled from 'atoms/SvgIcons/IconCopyFilled.svg';

const iconColorCode = {
    IconFacebook: 'hover:fill-other-facebook',
    IconTwitter: 'hover:fill-other-twitter',
    IconLinkedin: 'hover:fill-other-linkedin',
    IconYoutube: 'hover:fill-other-youtube',
    IconCopyFilled: 'hover:fill-primary1-500',
};

const SOCIAL_ICONS = { IconFacebook, IconTwitter, IconLinkedin, IconYoutube, IconCopyFilled };

const SocialList = ({ className, socialLinks, size = 'w-8', onClick }) => {
    // Function to handle click events and track with MoEngage
    const handleClick = (iconType) => {
        if (onClick) onClick(iconType); // Call the provided onClick function
    };

    return (
        <div className={`${className} flex`}>
            {socialLinks?.map(({ route, iconType, ariaLabel }) => {
                const Icon = SOCIAL_ICONS[iconType]; // Get the corresponding icon component
                return (
                    <LinkAnchor
                        key={iconType}
                        href={route}
                        target="_blank"
                        dataAutomation={`social-icon-${iconType}`}
                        aria-label={ariaLabel}
                        onClick={() => handleClick(iconType)} // Use handleClick for tracking
                    >
                        <Icon
                            className={`${iconColorCode[iconType]} ${size} fill-gray-900 transition-all duration-200`}
                        />
                    </LinkAnchor>
                );
            })}
        </div>
    );
};

export default SocialList;
